

/* Корневой блок, на всю высоту экрана */
.useful-block {
    background-color: #00bfa5;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* прижимает нижнюю часть к низу экрана */
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

/* Обёртка для верхней части (ссылки + форма).
   Ограничиваем ширину и центрируем */
.useful-block-top-inner {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    width: 100%;
    box-sizing: border-box;
}

/* Верхняя часть: две колонки */
.useful-block-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
}

/* Левая колонка (links) */
.links-column h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
}

.links-column ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.links-column li {
    margin-bottom: 6px;
}

.links-column a {
    color: #fff;
    text-decoration: none;
    font-size: 0.95rem;
    transition: opacity 0.2s;
}

.links-column a:hover {
    opacity: 0.8;
}

/* Правая колонка (newsletter) */
.newsletter-column h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
}

.newsletter-column p {
    margin: 0 0 1rem;
    font-size: 1rem;
    line-height: 1.4;
}

/* Форму делаем 2x2 grid */
.newsletter-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 1rem;
}

.newsletter-form input, .newsletter-form button {
    border: none;
    border-radius: 4px;
    font-size: 1rem;    /* или 1.1rem, по вкусу */
    padding: 12px;      /* было 8px, теперь 12px для «просторнее» */
    outline: none;
}

/* Кнопка */
.newsletter-form button {
    background-color: #fff;
    padding: 12px 20px;
    color: #00bfa5;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
}

.newsletter-form button:hover {
    background-color: #00bfa5;
    color: #fff;
}

/* ====== НИЖНЯЯ ЧАСТЬ (FOOTER) ====== */
.footer {
    width: 100%;
    background-color: #00bfa5; /* Оставляем бирюзовый, если нужно */
}

.footer-inner {
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px 40px; /* поля для футера */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Контактные колонки */
.footer-contacts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.footer-col {
    flex: 1;
    min-width: 200px; /* чтобы колонки не сжимались слишком */
}

.footer-col h4 {
    margin: 0 0 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
}

.footer-col p {
    margin: 0 0 1rem;
    font-size: 0.9rem;
    line-height: 1.3;
}

.footer-col a {
    color: #fff;
    text-decoration: underline;
}

/* Соцсети */
.footer-social {
    display: flex;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;
}

.footer-social a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
    border: 1px solid #fff;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 0.8rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}

.footer-social a:hover {
    background-color: #fff;
    color: #00bfa5;
    border-color: #00bfa5;
}

/* Адаптивность: < 768px -> всё в столбик */
@media (max-width: 768px) {
    .useful-block-top {
        flex-direction: column;
        align-items: flex-start;
    }
    .newsletter-column {
        flex: 0 0 40%;
    }

    /* Форма в одну колонку (или можно оставить 2, как захотите) */
    .newsletter-form {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, auto);
    }

    .footer-contacts {
        flex-direction: column;
        align-items: flex-start;
    }
}
