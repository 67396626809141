@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


body {
     font-family: 'Montserrat', sans-serif;
     font-size: 18px;
     font-weight: 400;
     margin: 0;
     letter-spacing: initial;
}

.header {
     position: fixed;
     z-index: 2;
     width: 100%;
     background-color: rgba(0, 0, 0, 0);
     transition: padding-top 0.4s ease, background-color 0.4s ease;
}

.header.scrolled {
     background: #313F50;
     padding-top: 15px;
     padding-bottom: 10px;
}

.container {
     max-width: 1300px;
     margin: 0 auto;
     width: auto;
}

.main-container {
     position: relative;
     height: 100%;
     width: 100%;
     overflow: hidden;
     z-index: 1;
}

.background-image::after {
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: rgba(49, 63, 80, 0.8);
}

.background-block {
     position: relative;
     padding-top: 9.3rem;
     color: white;
     z-index: 1;
}

.action-block ul {
     display: flex;
     justify-content: space-between;  /* to space the li items evenly */
     padding-left: 0;
}
.action-block-online ul {
     padding-top: 50px;
     display: flex;
     justify-content: space-evenly;  /* to space the li items evenly */
     padding-left: 0;
}
.popup-container {
     display: flex;
     justify-content: center;
     gap: 80px;
}

/*.my-custom-popup-styles {*/
/*     background: white;*/
/*     padding: 20px 30px;*/
/*     text-align: left;*/
/*     max-width: 400px;*/
/*     margin: 40px auto;*/
/*     position: relative;*/
/*     border-radius: 20px;*/
/*     width: 100%;*/
/*     height: auto;*/
/*     padding-top: 0 !important;*/
/*}*/
/*.action-block ul li::after {*/
/*     content: '';*/
/*     position: absolute;*/
/*     top: 37%;*/
/*     left: 50%;*/
/*     transform: translate(-50%, -86%);*/
/*     width: 50px;*/
/*     height: 50px;*/
/*     background-repeat: no-repeat;*/
/*     background-position: center;*/
/*     background-size: contain;*/
/*}*/

.action-block ul li {
     position: relative;
     display: flex;
     flex-direction: column;
     align-items: center;
     width: 20%;
     font-size: 21px;
}

/*.download-agenda::after {*/
/*     background-image: url('assets/adgenda.png');*/
/*}*/

/*.become-delegate::after {*/
/*     background-image: url('assets/delegate.png');*/
/*}*/

/*.event-page::after {*/
/*     background-image: url('assets/event.png');*/
/*}*/

/*.become-sponsor::after {*/
/*     background-image: url('assets/sponsor.png');*/
/*}*/

.download-agenda {
     display: flex;
     flex-direction: column;
     align-items: center;
}

nav ul li {
     color: white;
     list-style-type: none;
     font-size: 14px;
}
nav ul a {
     margin-left: 30px;
}

nav ul {
     display: flex;
}

.breadcrumbs-about li {
     list-style-type: none;
     font-size: 14px;
}

.breadcrumbs li {
     list-style-type: none;
     font-size: 14px;
}

.breadcrumbs li:first-child {
     padding-right: 16px;
}


.breadcrumbs ul {
     display: flex;
     padding-left: 0;
     justify-content: flex-start;
     padding-bottom: 0px;
     margin-bottom: 5px;
     margin-top: 0;
}

.breadcrumbs-about ul {
     display: flex;
     padding-left: 0;
     justify-content: flex-start;
     padding-bottom: 0;
     margin-bottom: 5px;
     margin-top: 20px;
}

h1 {
     font-style: normal;
     font-weight: 500;
     font-size: 64px;
     line-height: 80px;
     padding-top: 50px;
     width: 80%;
     margin-bottom: 0;
}

.title p {
     font-weight: 500;
     font-size: 18px;
     line-height: 22px;
}

.line {
     width: 1px;
     height: auto;
     background: #FFFFFF;
}

.action-block {
     padding-top: 100px;
     padding-bottom: 100px;
}

.line-title {
     display: inline-block;
     width: 171px;
     height: 2px;
     background: #D85121;
}

.line-breadcrumbs-one {
     width: 60px;
     position: absolute;
     height: 1px;
     background: rgba(16, 137, 241, 0.33);
}

.line-breadcrumbs {
     position: absolute;
     width: 0;
     height: 1px;
     background: #D85121;
     transition: all 0.5s ease-out;
}

.line-breadcrumbs:after {
     content: "";
     position: absolute;
     width: 12.5%;
     height: 1px;
     background: rgba(9, 9, 45, 0.8);
     left: 0;
     transition: all 0.5s ease-out;
}

.breadcrumbs-wrapper {
     display: flex;
}

.header.scrolled .desktop-header {
     padding-top: 0;
}

.title-about {
     display: flex;
     color: #313F50;
     padding-top: 150px;
     font-weight: 500;
     font-size: 48px;
     line-height: 60px;
     flex-direction: column-reverse;
     align-items: center;
     margin-bottom: 10px;
}

.line-about {
     display: inline-block;
     width: 171px;
     height: 2px;
     background: #D85121;
}

.description-about {
     padding-top: 20px;
     text-align: left;
     line-height: 21px;
}

.description-special {
     padding-top: 20px;
     text-align: center;
     line-height: 21px;

}


.about-container {
     text-align: center;
}

/*.title-about:after {*/
/*     position: absolute;*/
/*     width: 200px;*/
/*     height: 2px;*/
/*     background: #D85121;*/
/*     content: "";*/
/*     bottom: -340px;*/
/*}*/


.add-about {
     padding-top: 42px;
}

.last-about {
     padding-bottom: 132px;
}

.key-container {
     display: flex;
     justify-content: space-between;
     color: white;
     position: relative;
}

.key-container ul li {
     display: flex;
     list-style-type: none;
     align-items: center;
     padding-top: 50px;
     text-align: left;
     text-transform: none;
}

.section-keys {
     background: #313F50;
     width: 100%;
}

.vertical-line {
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     height: 100%;
     width: 1px;
     background: #D85121;
}

.key-container ul {
     padding-left: 0;
     padding-bottom: 50px;
}

.key-title {
     font-size: 24px;
     font-weight: 500;
     display: flex;
     justify-content: center;
     padding-top: 50px;
     padding-bottom: 30px;
     position: relative;
}

.key-title::before {
     content: "";
     position: absolute;
     bottom: -2px;
     left: 0;
     right: 0;
     margin: auto;
     width: 130px;
     height: 2px;
     background-color: #D85121;
}

.grid-container {
     display: grid;
     grid-template-columns: repeat(6, 1fr);
     gap: 12px; /* Если вы хотите отступ между элементами */
     min-height: 320px;
     padding-top: 60px;
     padding-left: 0;
}
.grid-container-custom {
     display: grid;
     grid-template-columns: repeat(6, 0fr);
     justify-content: center;
     gap: 12px; /* Если вы хотите отступ между элементами */
     min-height: 320px;
     padding-top: 60px;
     padding-left: 0;
}

.grid-container-teaser {
     display: flex;
     justify-content: space-between;
     max-width: 1300px;
     margin-top: 40px;
     margin-left: 1%;
     margin-right: 0;
     flex-direction: row-reverse;
     gap: 10px;
}
.grid-container-sponsor{
     display: grid;
     grid-template-columns: repeat(6, 200px);
     justify-content: space-between;
     gap: 12px; /* Если вы хотите отступ между элементами */
     min-height: 320px;
     padding-top: 60px;
     padding-left: 50px;
     padding-right: 50px;
}


.grid-item {
     list-style-type: none;
     display: flex;
     align-items: flex-start;
     flex-direction: column;
     width: 100%;
     height: 100%;
     padding-bottom: 60px;
     min-height: 320px;
}
.grid-item-teaser {
     list-style-type: none;
     display: flex;
     align-items: center;
     flex-direction: column;
     width: 100%;
     height: 100%;
     padding-bottom: 60px;
     min-height: 320px;
}
.line-container {
     display: flex;
     justify-content: center;
     height: 0;
}

.name {
     color: #313F50;
     font-size: 16px;
     font-weight: 700;
     margin-top: 10px;
     margin-bottom: 5px;
     text-transform: uppercase;
     text-align: left;
     padding-left: 5px;
     padding-right: 5px;
}
.name-special {
     color: #313F50;
     font-size: 16px;
     font-weight: 700;
     margin-top: 10px;
     margin-bottom: 5px;
     text-transform: uppercase;
     text-align: left;
     padding-left: 11px;
     padding-right: 5px;
}

.position {
     margin-top: 5px;
     margin-bottom: 5px;
     font-size: 14px;
     text-align: left;
     padding-left: 5px;
     padding-right: 5px;
}
.position-special {
     margin-top: 5px;
     margin-bottom: 5px;
     margin-right: 40%;
     font-size: 14px;
     text-align: left;
     padding-left: 5px;
     padding-right: 5px;
}

.company {
     margin-top: 5px;
     margin-bottom: 5px;
     font-size: 14px;
     text-align: left;
     padding-left: 5px;
     padding-right: 5px;
     font-weight: 500;
}

.flip-card {
     position: relative;
     perspective: 1000px;
     width: 100%;
     height: 100%;
}

.flip-card-inner {
     position: relative;
     width: 100%;
     height: 100%;
     transform-style: preserve-3d;
     transition: transform 0.5s;
}

.flip-card:hover .flip-card-inner {
     transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     backface-visibility: hidden;
     display: flex;
     flex-direction: column;
}

.flip-card-front {
     color: #000;
     align-items: center;
}

.flip-card-back {
     border: 1px solid #313F50;
     color: #313F50;
     transform: rotateY(180deg);
     align-items: center;
     justify-content: center;
     min-height: 300px;
     line-height: 120%;
     letter-spacing: normal;
     display: flex;
     word-spacing: 4px;
     text-align: center;
}

.scrollable-content p {
     font-size: 14px;
     color: white;
}

.partners-list li {
     opacity: 0.5;
     transition: opacity 0.3s ease;
}

.partners-list li:hover {
     opacity: 1;
}

.venue-section {
     background-color: #313F50;
     overflow: hidden;
}

.venue-title {
     font-size: 36px;
     font-weight: 700;
     color: #FFFFFF;
     text-align: center;
     padding-top: 2.5rem;
}

.venue-description {
     font-size: 14px;
     font-weight: 400;
     color: white;
     text-align: center;
}

.venue-btn {
     width: 200px;
     height: 44px;
     color: white;
     background: #D85121;
     align-items: center;
     border: 1px solid #D85121;
}

.btn-wrapper {
     margin-top: 40px;
     display: flex;
     justify-content: center;
}

.contact-list {
     display: flex;
     align-items: center;
     justify-content: space-around;
     letter-spacing: normal;
     padding-bottom: 150px;
}

.contact-list li {
     display: flex;
     flex-direction: column;
     align-items: center;
     color: #313F50;
}

.contact-list li h2 {
     font-size: 24px;
     font-weight: 500;
     color: #313F50;
     font-style: normal;
     margin-top: 40px;
     margin-bottom: 20px;
}

.contact-list li h4 {
     font-style: normal;
     font-weight: 500;
     font-size: 14px;
     margin-bottom: 0;
     margin-top: 10px;
}

.contact-list li h3 {
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     text-transform: uppercase;
     color: #313F50;
     margin-top: 20px;
     margin-bottom: 0;
}

.contact-list img {
     border-radius: 50%;
     width: 90px;
     height: 90px;
     object-fit: cover;
}

.contact-list li p {
     font-size: 14px;
     line-height: 18px;
     margin-bottom: 0;
     margin-top: 10px;
     display: flex;
     align-items: center;
}

.phone::before {
     content: "";
     display: inline-block;
     width: 16px; /* Ширина иконки */
     height: 16px; /* Высота иконки */
     background-image: url("../images/phone.png");
     background-size: contain; /* Масштабирование иконки */
     background-repeat: no-repeat;
     margin-right: 5px; /* Отступ между иконкой и текстом */
}

.email::before {
     content: "";
     display: inline-block;
     width: 20px; /* Ширина иконки */
     height: 20px; /* Высота иконки */
     background-image: url("../images/email.png");
     background-size: contain; /* Масштабирование иконки */
     background-repeat: no-repeat;
     margin-right: 5px; /* Отступ между иконкой и текстом */
     margin-top: 5px;
}

.phone {
     display: flex;
}
.email {
     display: flex;
}

footer {
     background: #313F50;
     height: 250px;
}

.footer-wrapper {
     display: flex;
     justify-content: center;
     max-width: 1300px;
     margin: 0 auto;
     height: 100%;
}

.social {
     display: flex;
     align-items: center;
     gap: 20px;
     justify-content: center;
}

.departaments {
     font-style: normal;
     font-weight: 600;
     font-size: 16px;
     color: #DDDDDD;
}

.departaments span {
     font-weight: 400;
}

.links a {
     font-weight: 600;
     font-size: 18px;
     color: #DDDDDD;
     text-decoration: none;
}

.social {
     width: 25%;
}

.departaments {
     width: 140%;
     display: flex;
     flex-wrap: wrap;
     justify-content: space-around;
     align-content: stretch;
}

.links {
     display: flex;
     width: 30%;
     flex-direction: column;
}

.social-container {
     display: flex;
     flex-direction: column;
     justify-content: space-around;
     align-items: center;
     width: 35%;
}

.departaments ul {
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
}

.departaments ul li {
     padding-top: 27px;
}

.links a {
     padding-top: 25px;
     margin-top: 0;
}

.mfp-bg {
     background: #313F50 !important;
}


.my-custom-popup-style {
     max-height: 400px !important;
     background: white;
     padding: 20px 30px;
     text-align: left;
     max-width: 400px;
     margin: 40px auto;
     position: relative;
     border-radius: 20px;
     /* Дополнительные стили для попапа */
}

.my-custom-popup-style .zoom-anim-dialog {
     opacity: 0;
     -webkit-transition: all 0.2s ease-in-out;
     -moz-transition: all 0.2s ease-in-out;
     -o-transition: all 0.2s ease-in-out;
     transition: all 0.2s ease-in-out;
     -webkit-transform: scale(0.8);
     -moz-transform: scale(0.8);
     -ms-transform: scale(0.8);
     -o-transform: scale(0.8);
     transform: scale(0.8);
     /* Дополнительные стили для анимации попапа */
}

.my-custom-popup-style.mfp-ready .zoom-anim-dialog {
     opacity: 1;
     -webkit-transform: scale(1);
     -moz-transform: scale(1);
     -ms-transform: scale(1);
     -o-transform: scale(1);
     transform: scale(1);
}

.my-custom-popup-style.mfp-removing .zoom-anim-dialog {
     -webkit-transform: scale(0.8);
     -moz-transform: scale(0.8);
     -ms-transform: scale(0.8);
     -o-transform: scale(0.8);
     transform: scale(0.8);
     opacity: 0;
}

.my-custom-popup-style.mfp-bg {
     background-color: #313F50 !important;
     opacity: 0;
     -webkit-transition: opacity 0.3s ease-out;
     -moz-transition: opacity 0.3s ease-out;
     -o-transition: opacity 0.3s ease-out;
     transition: opacity 0.3s ease-out;
     /* Дополнительные стили для фона вокруг попапа */
}

.my-custom-popup-style.mfp-ready.mfp-bg {
     opacity: 0.8;
}

.my-custom-popup-style.mfp-removing.mfp-bg {
     opacity: 0;
}

/* Дополнительные стили для содержимого попапа */
.popup-img {
     width: 150px;
     border-radius: 50%;
     height: 150px;
}

.popup-flex {
     display: flex;
     justify-content: space-around;
}

.popup-direction {
     display: flex;
     flex-direction: column;
     justify-content: space-evenly;
}

.popup-desc {
     font-size: 14px;
     line-height: 21px;
     word-wrap: break-word;
     hyphens: auto;
}

.popup-with-zoom-anim {
     display: flex;
     text-decoration: none;
     color: white;
     transition: all 0.3s ease-in;
     flex-direction: column;
     align-items: center;
     text-align: center;
}

.popup-with-zoom-anim:hover {
     text-decoration: none;
     color: #D85121;
     transition: all 0.3s ease-in;
}

.popup-item {
     width: 180px;
     height: 180px;
     background-color: white; /* Цвет квадрата */
     border-radius: 20px;
     margin-bottom: 10px;
     font-style: normal;
     font-weight: 400;
     font-size: 18px;
     line-height: 40px;
     color: #313F50;
     align-items: flex-end;
     justify-content: center;
     display: flex;
     cursor: pointer;
}

.text-popup {
     padding-bottom: 10px;
     font-weight: 400;
     font-size: 18px;
     line-height: 40px;
}

.white-square {
     width: 597px;
     height: auto;
     background-color: white;
     margin: 0 auto;
     border-radius: 20px;
}

.popup-container {
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: center;
     height: 100%;
}

.form-group {
     display: flex;
     flex-direction: column;
     padding-left: 30px;
     padding-right: 30px;
     border-radius: 20px !important;
}
.radio-buttons {
     display: flex;
     flex-wrap: wrap;
     gap: 5px; /* Устанавливает отступы между радиокнопками */
}

.radio-label {
     margin-right: 10px; /* Устанавливает отступы между меткой и радиокнопкой */
}
.radio-option {
     display: block;
     margin-bottom: 0; /* Устанавливаем отступ снизу между радиокнопками */
}

.form-bank div input {
     height: 30px;
     border: 1px solid #313F50;
     font-family: Montserrat, sans-serif;
     padding-left: 5px;
     padding-right: 5px;
}

.register-button {
     margin-left: 30px;
     width: 150px;
     height: 36px;
     background: #D85121;
     margin-top: 20px;
     border: 1px solid #D85121;
     margin-bottom: 30px;
     color: #FFFFFF;
}

.form-group label {
     color: #313F50;
     font-size: 16px;
     padding-bottom: 10px;
     padding-top: 10px;
}

/*.form-group label:first-child {*/
/*     padding-top: 20px;*/
/*}*/

.scroll-container {
     max-height: 600px;
     overflow-y: auto;
}

.scroll-container::-webkit-scrollbar {
     width: 7px; /* Ширина скроллера */
}

.scroll-container::-webkit-scrollbar-thumb {
     background-color: #D85121 /* Цвет скроллера */
}

.first-name-class {
     padding-top: 30px !important;
}

.form-group select {
     height: 30px;
     border: 1px solid #313F50;
     font-family: Montserrat, sans-serif;
}

.scroll-container-wrapper {
     border-radius: 20px;
     overflow: hidden;
}

.scroll-container {
     max-height: 600px;
     overflow-y: auto;
}

.scroll-container::-webkit-scrollbar {
     width: 7px;
}

.scroll-container::-webkit-scrollbar-thumb {
     background-color: #D85121;
}

.close-button {
     position: fixed;
     top: 25px;
     right: 50px;
     width: 60px;
     height: 60px;
     color: #fff;
     text-align: center;
     line-height: 59px;
     font-size: 45px;
     cursor: pointer;
}

.mfp-close-btn-in .mfp-close {
     display: none;
}

.paypal-square {
     width: 597px;
     height: auto;
     background-color: white;
     margin: 0 auto;
     padding: 20px;
     border-radius: 20px;
}

.paypal-container {
     padding-left: 30px;
     padding-right: 30px;
}

.paypal-wrapper {
     display: flex;
     justify-content: space-around;
     align-items: center;
     border-bottom: solid 1px #D85121;
}

.buy-now {
     background: #D85121;
     width: 200px;
     height: 44px;
     border: 1px solid #D85121;
     color: #FFFFFF;
}

.price-paypal {
     text-align: center;
}

@media screen and (min-width: 1023px) and (max-width: 1111px) {
     .container {
          max-width: 950px;
     }
     .grid-container-teaser {
          margin-left: 10%;
          margin-right: 7%;
     }
     .desktop-header {
          max-width: 950px;
     }
     h1 {
          width: 100%;
          font-size: 48px;
     }
     .action-block ul li {
          font-size: 17px;
     }
     .links a {
          padding-top: 22px;
          font-size: 14px;
     }
     .departaments ul li {
          font-size: 14px;
     }
     .social {
          gap: 14px;
     }
     .key-container ul li {
          font-size: 16px;
     }
}

@media screen and (min-width: 1111px) and (max-width: 1365px) {
     .container {
          max-width: 1050px;
     }
     .desktop-header {
          max-width: 1050px;
     }
     h1 {
          width: 90%;
     }
     .action-block ul li {
          font-size: 19px;
     }
     .links a {
          font-size: 16px;
     }
}

@media screen and (min-width: 1023px) and (max-width: 1365px) {
     .title p {
          padding-top: 35px;
     }

}

@media screen and (max-width: 1023px) {
     .desktop-header {
          display: none;
     }
}

.mobile-header {
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     z-index: 100;
     transition: background-color 0.5s ease-in-out;
}

.mobile-class {
     height: 54px;
     padding: 14px 0 0 0;
     display: flex;
     justify-content: space-between;
     align-items: center;
}

.menu {
     background-color: transparent;
     border: none;
     cursor: pointer;
     display: flex;
     padding: 0;
}
.line {
     fill: none;
     stroke: #FFFFFF;
     stroke-width: 1;
     transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
     stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
     stroke-dasharray: 60 207;
     stroke-width: 2;
}
.line2 {
     stroke-dasharray: 60 60;
     stroke-dashoffset: -10;
     stroke-width: 2;
}
.line3 {
     stroke-dasharray: 40 207;
     stroke-dashoffset: -20;
     stroke-width: 2;
}
.opened .line1 {
     stroke-dasharray: 90 207;
     stroke-dashoffset: -134;
     stroke-width: 2;
}
.opened .line2 {
     stroke-dasharray: 1 60;
     stroke-dashoffset: -30;
     stroke-width: 2;
}
.opened .line3 {
     stroke-dasharray: 90 207;
     stroke-dashoffset: -134;
     stroke-width: 2;
}

.mobile-nav.open {
     display: flex;
     transform: translateX(0);
     transition: transform 0.3s ease-in;
     z-index: 1;
     background: #313F50;
     flex-direction: column;
     align-items: center;
     justify-content: flex-start;
}

.mobile-list {
     font-family: 'Artegra Sans Extended', serif;
     font-weight: 400;
     font-size: 16px;
     line-height: 40px;
     color: #FFFFFF;
     padding-top: 10px;
}

.mobile-nav {
     display: flex;
     position: fixed;
     top: 0;
     right: 0;
     bottom: 0;
     width: 100%;
     transform: translateX(100%);
     transition: transform 0.3s ease-out;
     background: #313F50;
     justify-content: center;
     align-items: flex-start;
     z-index: 1;
}

.mobile-nav ul a {
     text-decoration: none;
}

@media screen and (max-width: 767px) {
     .laptop-footer {
          display: none;
     }
     .grid-container-teaser {
          margin-left: 2%;
          margin-right: 1%;
          gap: 10px;
     }
     .grid-container-sponsor{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 120px; /* Если вы хотите отступ между элементами */
          min-height: 320px;
          padding-top: 5px;
          padding-left: 100px;
          padding-right: 100px;
     }
     .special-style {
          display: flex;
          flex-direction: column;
          align-items: center;
     }
     .container {
          padding-left: 10px;
          padding-right: 10px;
     }
     .contact-list li p {
          font-size: 10px;
     }
     .contact-list {
          flex-direction: column;
          padding-bottom: 60px;
     }
     .contact-list li h4 {
          font-size: 10px;
     }
     .contact-list li h3 {
          font-size: 14px;
     }
     .contact-list li h2 {
          font-size: 16px;
          margin-top: 40px;
     }
     .swiper-container {
          padding-top: 3rem !important;
     }
     .venue-title {
          padding-top: 0 !important;
          font-size: 16px !important;
     }
     .section-speakers {
          padding-bottom: 60px;
     }

     .title-gallery {
          font-size: 20px !important;
          line-height: 100% !important;
     }
     h1 {
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          width: 100%;
          margin-bottom: 0;
     }

     .title p {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
     }

     .popup-with-zoom-anim p {
          font-size: 10px;
          text-align: center;
          font-weight: 400;
          line-height: 12px;
     }

     .popup-with-zoom-anim img {
          height: 32px;
          width: 32px;
     }

     .line-title {
          display: none;
     }

     .header.scrolled {
          padding-top: 62px;
     }

     .background-block {
          padding-top: 3.3rem !important;
     }

     .line {
          width: 0.2px;
          height: auto;
          background: #ffffff57;
     }

     .white-square {
          width: 90% !important;
     }

     .close-button {
          top: 5px !important;
          right: 0 !important;
     }

     .form-group label {
          font-size: 10px !important;
     }

     .popup-item {
          width: 119px !important;
          height: 119px !important;
     }

    .popup-container {
          flex-direction: column;
     }

     .text-popup {
          font-size: 13px !important;
     }

     .item-2 {
          background-size: 71px 19px;
     }

     .item-1 {
          background-size: 43px 41px;
          background-position-y: 23px !important;
     }

     .item-3 {
          background-size: 50px 52px;
     }

     .popup-container {
          gap: 0;
     }

     .paypal-square {
          width: 85% !important;
     }

     .paypal-wrapper div h4 {
          font-size: 12px;
          text-align: left;
     }

     .price-paypal {
          font-size: 10px;
          padding-left: 0 !important;
     }

     .buy-now {
          width: 130px !important;
          height: 34px !important;
     }

     .paypal-container {
          padding-left: 10px !important;
          padding-right: 10px !important;
     }

     /*.form-group label:first-child {*/
     /*     padding-top: 10px;*/
     /*}*/


     .title-about {
          font-size: 20px;
          padding-top: 60px;
          line-height: 100%;
     }

     .description-about {
          font-size: 14px;
     }
     .description-special {
          font-size: 14px;
     }

     .add-about {
          padding-top: 20px !important;
          padding-bottom: 20px !important;
     }

     .last-about {
          padding-bottom: 60px;
     }

     .vertical-line {
          display: none;
     }

     .key-container {
          flex-direction: column;
     }

     .key-title {
          font-size: 20px;
          padding-bottom: 10px;
          padding-top: 0;
     }

     .key-title::before {
          height: 0.5px;
     }

     .key-container ul li {
          font-size: 14px;
          padding-top: 10px;
     }

     .key-container ul {
          padding-bottom: 0;
     }

     .grid-container {
          grid-template-columns: repeat(3, 1fr);
          padding-top: 0;
     }
     .grid-container-custom {
          grid-template-columns: repeat(3, 0fr);
          padding-top: 0;
     }
     .name {
          font-size: 10px !important;
     }
     .position {
          font-size: 8px;
     }
     .company {
          font-size: 8px;
     }
     .logo-company {
          width: 42px;
          height: 19px;
     }
     .mobile-image {
          width: 87px !important;
          height: 87px !important;
     }
     .line-about {
          width: 108px !important;
     }
     .grid-item {
          min-height: auto;
          padding-bottom: 20px;
     }
     .popup-img {
          width: 87px !important;
          height: 87px !important;
     }
     .popup-desc {
          line-height: 20px;
          word-wrap: break-word;
          font-size: 12px !important;
          hyphens: auto;
          -webkit-hyphens: auto;
          -ms-hyphens: auto;
          -moz-hyphens: auto;
     }
     .mobile-company {
          font-size: 12px;
     }
     .mobile-name {
          font-size: 14px !important;
     }
     .mobile-position {
          font-size: 10px !important;
     }
     .partners-list {
          padding-top: 0 !important;
          flex-direction: column;
          gap: 12px;
          padding-bottom: 60px !important;
     }
     .partners-list li img {
          width: 100px;
     }
     .partners-list li {
          text-align: center;
     }
     footer {
          height: 100%;
     }
     .footer-wrapper {
          display: none;
     }
     .social-mobile-footer {
          display: flex;
          justify-content: center;
     }
     .footer-logo {
          text-align: center;
          padding-top: 20px;
     }
     .footer-list li {
          display: flex;
          color: #DDDDDD;
          flex-direction: column;
          align-items: center;
          font-size: 10px;
          gap: 12px;
          font-weight: 800;
     }
     .footer-list li span {
          font-weight: 400;
     }
     .footer-logo img {
          width: 90px;
          height: 37px;
     }
     .footer-list {
          gap: 12px;
          display: flex;
          flex-direction: column;
     }
     .copyright-mobile {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: #F9F9F9;
          margin-bottom: 0;
          padding-bottom: 20px;
     }
     .links-mobile {
          margin: 12px;
          font-size: 10px;
          line-height: 24px;
          color: #DDDDDD;
          display: flex;
          align-items: center;
          flex-direction: column;
     }
     .links-mobile a {
          text-decoration: none;
          color: #DDDDDD;
          font-weight: 800;
     }
     .line-footer {
          width: 100%;
          height: 0.2px;
          /*background-color: #FFFFFF;*/
          display: inline-block;
     }
     .mobile-header {
          padding-right: 15px;
          padding-left: 15px;
     }
     .footer-mobile {
          display: flex;
          align-content: stretch;
          justify-content: space-evenly;
          flex-wrap: wrap;
          flex-direction: column;
     }

}
@media screen and (min-width: 319px) and (max-width: 424px) {
     .key-6::before {
          width: 37px;
     }
}


@media screen and (min-width: 1023px) and (max-width: 1100px) {
     .grid-container {
          grid-template-columns: repeat(4, 1fr);
     }
     .grid-container-custom{
          grid-template-columns: repeat(4, 0fr);
     }
}

@media screen and (min-width: 1100px) and (max-width: 1443px) {
     .grid-container {
          grid-template-columns: repeat(5, 1fr);
     }
     .grid-container-custom {
          grid-template-columns: repeat(5, 0fr);
     }
}

/*@media screen and (min-width: 424px) and (max-width: 767px) {*/
/*     .key-container ul li {*/
/*          display: flex;*/
/*          justify-content: center;*/
/*     }*/
/*}*/

@media screen and (min-width: 424px) and (max-width: 539px) {
     .grid-container {
          grid-template-columns: repeat(4, 1fr);
     }
     .grid-container-custom {
          grid-template-columns: repeat(4, 0fr);
     }
}

@media screen and (min-width: 539px) and (max-width: 767px) {
     .grid-container {
          grid-template-columns: repeat(5, 1fr);
     }
     .grid-container-custom {
          grid-template-columns: repeat(5, 0fr);
     }
}

@media screen and (min-width: 1023px) {
     .mobile-menu {
          display: none;
     }
     .background-mobile {
          display: none;
     }
     .mobile-title-line {
          display: none !important;
     }
     .footer-mobile {
          display: none;
     }
     .laptop-footer {
          display: none;
     }
}

.background-mobile {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-image: url('../images/background-about.png');
     background-repeat: no-repeat;
     background-size: cover;
     background-position: center;
     z-index: 0;
}

.background-mobile::after {
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: rgba(9, 9, 45, 0.8);
}

.block-output {
     display: flex;
}

.list-navigation {
     padding-top: 6.5rem;
     text-align: center;
}

.line-burger {
     display: inline-block;
     width: 280px;
     height: 1px;
     background: #D85121;
     transition: transform 0.3s ease-out;
}

.social-mobile {
     display: flex;
     justify-content: space-evenly;
     align-items: center;
     padding-top: 1.1rem;
}

.mobile-title-line {
     display: inline-block;
     width: 171px;
     height: 1px;
     background: #D85121;
}

.mobile-line-container {
     display: flex;
     justify-content: center;
     margin-top: 40px;
     margin-bottom: 40px;
}

.close-button .line1, .close-button .line2 {
     position: absolute;
     width: 100%;
     height: 2px;
     background: white;
}

.close-button .line1 {
     transform: rotate(45deg);
     transform-origin: center;
     top: 2rem;
}

.close-button .line2 {
     transform: rotate(-45deg);
     transform-origin: center;
     top: 2rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
     .background-laptop::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(9, 9, 45, 0.8);
     }

     .svg-mobile-hamburger {
          width: 80px;
          height: 80px;
     }

     .logo-laptop {
          width: 96px !important;
          height: 35px !important;
     }

     .mobile-header {
          padding-right: 20px;
          padding-left: 20px;
     }

     .container {
          padding-left: 20px;
          padding-right: 20px;
     }

     .header.scrolled {
          padding-top: 70px;
     }

     h1 {
          font-size: 48px;
          width: 100%;
     }

     .mobile-title-line {
          display: none;
     }

     .line-title {
          bottom: 51%;
     }

     .title p {
          font-size: 16px;
     }

     .action-block ul li {
          font-size: 18px;
     }

     .action-block ul li p {
          text-align: center;
     }

     .popup-with-zoom-anim img {
          width: 44px;
          height: 44px;
     }

     .title-about {
          font-size: 36px;
     }

     .description-about {
          font-size: 14px;
     }
     .description-special {
          font-size: 14px;
     }


     .close-button {
          right: 20px !important;
     }

     .title-about {
          padding-top: 70px !important;
     }
     .key-title {
          font-size: 20px;
     }

     .last-about {
          padding-bottom: 86px;
     }

     .key-container ul li {
          font-size: 12px;
     }
     .mobile-image {
          width: 174px !important;
          height: 147px !important;
     }

     .grid-container {
          grid-template-columns: repeat(4, 1fr);
     }
     .grid-container-custom {
          grid-template-columns: repeat(4, 0fr);
     }
     .grid-item {
          padding-bottom: 0;
     }
     .section-speakers {
          padding-bottom: 70px;
     }
     .title-gallery {
          font-size: 36px !important;
     }
     .partners-list li {
          text-align: center;
          width: 50%;
     }
     .partners-list {
          align-items: center;
          justify-content: space-around;
          flex-wrap: wrap;
          padding-bottom: 70px;
          padding-top: 40px;
     }
     .venue-title {
          font-size: 24px !important;
          padding-top: 1rem !important;
     }
     .contact-list li h2 {
          font-size: 18px;
          margin-top: 40px;
     }
     .contact-list li h3 {
          font-size: 14px;
     }
     .contact-list li h4 {
          font-size: 10px;
     }
     .contact-list li p {
          font-size: 10px;
     }
     .contact-list {
          padding-bottom: 70px;
     }
}

@media screen and (min-width: 767px) {
     .special-style {
          display: flex;
          justify-content: center;
     }

     .item-2 {
          margin-left: 60px;
     }

     .item-3 {
          margin-left: 60px;
     }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
     .footer-wrapper {
          display: none;
     }
     .grid-container-teaser {
          margin-left: 5%;
          margin-right: 5%;
     }
     .footer-mobile {
          display: none;
     }
     .laptop-logo-img {
          width: 96px;
          height: 35px;
     }
     .flex-laptop {
          display: flex;
          padding-left: 20px;
          padding-right: 20px;
          justify-content: space-between;
          padding-top: 20px;
     }
     .social {
          padding-right: 15px;
     }
     .line-footer-laptop {
          width: 100%;
          height: 0.2px;
          background-color: #FFFFFF;
          display: inline-block;
     }
     .laptop-flex {
          display: flex;
          justify-content: space-between;
          padding-right: 20px;
          padding-left: 20px;
     }
     .laptop-flex li p {
          font-size: 14px;
     }
     footer {
          height: 100%;
     }
     .title-laptop {
          font-weight: 600;
          font-size: 14px;
          color: #FFFFFF;
     }
     .contact-laptop {
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
     }
     .copyright-laptop {
          font-size: 12px;
          line-height: 15px;
          color: #F9F9F9;
          text-align: center;
          padding-bottom: 20px;
     }
     .mobile-list {
          font-size: 24px !important;
     }
}

.my-mfp-fade-in .mfp-content {
     opacity: 0;
     transition: opacity 0.3s ease-in-out;
}

.my-mfp-fade-in.mfp-ready .mfp-content {
     opacity: 1;
}

.my-mfp-fade-in.mfp-removing .mfp-content {
     opacity: 0;
}

.my-mfp-slide-in .mfp-content {
     opacity: 0;
     transform: translateY(100%);
     transition: all 0.4s ease-in-out;
}

.my-mfp-slide-in.mfp-ready .mfp-content {
     opacity: 1;
     transform: translateY(0);
}

.my-mfp-slide-in.mfp-removing .mfp-content {
     opacity: 0;
     transform: translateY(100%);
}

.mfp-container {
     position: fixed;
}

@media screen and (max-width: 400px) {
     .paypal-button-row {
          width: 100px !important;
     }
     .buttons-container {
          display: flex !important;
          justify-content: center !important;
     }
}
