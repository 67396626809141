.marquee-container {
    display: flex;
    overflow: hidden;
    margin-bottom: -90px;
}

.horizontal-buttons-new {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 20px; /* Отступ сверху */
    text-align: center;
}

.payment-option-new {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 240px; /* Одинаковая ширина для всех блоков */
    margin: 10px 20px; /* Отступы между блоками */
}

.popup-item-new {
    background-color: white;
    margin: 0 auto;
    text-align: center;
    border: none;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Прижимаем элементы к нижней части */
    align-items: center;
    height: 180px; /* Ограничим высоту, чтобы сохранить размерность */
}
.payment-option-new:hover .popup-item-new,
.payment-option-new:hover .payment-link-new {
    background-color: bisque;
    box-shadow: none;
    border: none;
    color: initial;
}
.text-popup {
    color: #313F50;
    margin-top: auto; /* Отступ сверху, чтобы текст прижался к кнопке */
    padding-top: 5px; /* Уменьшаем отступ сверху для текста */
    font-size: 16px;
    font-weight: 400;
    line-height: 1; /* Немного уменьшим высоту строки */
}

.order-button-new {
    margin: 5px;
    padding: 12px 25px; /* Увеличиваем размеры кнопки */
    background-color: #D85121;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%; /* Кнопка растягивается на всю ширину */
    font-size: 16px; /* Чуть больший размер текста для кнопок */
}

.order-button-new:hover {
    background-color: #0056b3;
}

/* Мобильная версия */
@media (max-width: 768px) {
    .horizontal-buttons-new {
        flex-direction: column;
        align-items: center;
    }

    .payment-option-new {
        width: 100%;
        margin: 10px 0;
    }

    .order-button-new {
        width: 100%;
    }
}

.payment-link-new {
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: center;
}

.payment-link-new:hover {
    background-color: #07073a;
    transition: background-color 0.3s ease;
}


.marquee {
    list-style: none;
    padding: 10px;
    margin: 0;
    white-space: nowrap;
    animation: marqueeAnimation linear infinite;
    display: flex; /* Используем flex-контейнер для управления расположением элементов */
}

.marquee-item {
    width: 100%; /* Задайте фиксированную ширину */
    max-height: 60px; /* Задайте фиксированную высоту (если нужно) */
    margin-right: 20px; /* Регулируйте расстояние между логотипами */
    overflow: hidden; /* Обрезать изображение, если оно превышает размеры блока */

}
.marquee-item-wrapper {
    width: 100%; /* Задайте фиксированную ширину */
    height: 50%; /* Задайте фиксированную высоту (если нужно) */
    overflow: hidden; /* Обрезать изображение, если оно превышает размеры блока */
}
.marquee-item-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Или другие значения object-fit, соответствующие вашим требованиям */
}
@keyframes marqueeAnimation {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}
.marquee {
    /* ... Ваши предыдущие стили ... */
    animation: marqueeAnimation 20s linear infinite; /* 10s - это длительность анимации, вы можете изменить на нужное вам значение */
}
@keyframes flashAnimation {
    0%, 50%, 100% {
        color: #D85121;
    }
    25%, 75% {
        color: #fff;
    }
}

.neon-button {
    margin-top: 10px;
    margin-bottom: 50px;
    background-color: #D85121;
    color: #fff;
    border: none;
    padding: 20px 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 24px;
    cursor: pointer;
    border-radius: 5px;
    animation: flashAnimation 10s infinite;
}
@media (max-width: 768px) {
    .neon-button {
        font-size: 18px; /* Меньший шрифт для кнопок */
        padding: 10px 15px; /* Уменьшение паддингов */
        margin: 20px;
    }
}

/* styles.css */
@media (max-width: 600px) {
    .chart-legend-container {
        display: flex;
        flex-direction: column;
    }
    .chart-container {
        order: 1;
    }
    .legend-container {
        order: 2;
        margin-top: 20px;
    }
}


